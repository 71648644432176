import {IUseCase} from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import MitsubishiRepository from '../../../data/repository/mitsubishi';
import { notifyError, notifySuccess } from '../../../utils/toast';
export default class ImportFilesCase implements IUseCase {
    private mitsubishiRepository: MitsubishiRepository;

    /**
     *
     */
    constructor(public payload: any) {
        this.mitsubishiRepository = new MitsubishiRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.mitsubishiRepository.importFilesRepository(this.payload).then((response: any) => {
                //console.log('getOrderUseCase:::2', response.data);
                if (response.data.success) {
                    resolve(response.data);
                    if(response.data.data.totalValidRecord > 0){
                        notifySuccess('価格のインポートに成功しました!');
                    }                    
                } else {
                    notifyError("失敗");
                    reject(ResponseModel.createError(500, response.data.errorMessage));
                }
            }).catch(reject);
        });

    };

}
