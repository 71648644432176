import { combineEpics } from "redux-observable";
import { initAppEpic } from "../rootEpics/general/appInitiation";
import { usersEpic } from "../epics/users";
import { customersEpic } from "../epics/customers";
import { ordersEpic } from "../epics/orders";
import { productsEpic } from "../epics/products";
import { salesEpic } from "../epics/sales";
import { notificationEpic } from "../epics/notification";
import { invoicesEpic } from "../epics/invoices";
import { tempProductsEpic } from "../epics/tempProducts";
import { flyersEpic } from "../epics/flyers";
import { mitsubishiEpic } from "../epics/mitsubishi";
import { orderOnBehalfEpic } from "../epics/orderonbehalf";

export const rootEpic: any = combineEpics(
  initAppEpic,
  usersEpic,
  customersEpic,
  ordersEpic,
  productsEpic,
  salesEpic,
  notificationEpic,
  invoicesEpic,
  tempProductsEpic,
  flyersEpic,
  mitsubishiEpic,
  orderOnBehalfEpic
);
